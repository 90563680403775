import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

import StripeElementProvider from "./StripeElementProvider";
import { RentAmountContext } from "../../context/AppContext";

import "./Checkout.css";

const Checkout = () => {
  const [client_secret, setClient_Secret] = useState(null);

  const { rentAmount, originalAmount, setErrorMessage, isProcessingFee } =
    useContext(RentAmountContext);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        if (rentAmount <= 0) {
          setErrorMessage("Rent amount cannot be zero or less");
          navigate("/");
          return;
        }

        const response = await fetch("/createCharge", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            amount: Math.abs(rentAmount),
          }),
        });

        const data = await response.json();
        setClient_Secret(data.client_secret);
      } catch (error) {
        console.log(error.message);
      }
    })();
  }, [navigate, rentAmount, setErrorMessage]);

  return (
    <div className="checkout-wrapper">
      <div className="payment-summary">
        <h3>Payment Summary</h3>
        <p>Rent Amount: {originalAmount} </p>

        <p>
          Processing Fees:
          {isProcessingFee
            ? parseFloat(originalAmount * 0.03).toFixed(2)
            : "Free"}
        </p>

        <p className="total-payment">Total Rent Payment $: {rentAmount} </p>

        <p className="manual-payment-verification-message">
          Please note that if you pay using your bank details (if you manually
          enter your routing and account numbers), you will see a $0.01 deposit
          in your account in 1-2 business days. Then you'll get an email with
          instructions to complete payment to Jousta LLC.
        </p>
      </div>
      {client_secret ? (
        <StripeElementProvider client_secret={client_secret} />
      ) : undefined}
    </div>
  );
};

export default Checkout;
