import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { RentAmountContextProvider } from "./context/AppContext";
import Home from "./page/Home";
import SignUp from "./components/signInAnUp/SignUp";
import SignIn from "./components/signInAnUp/SignIn/SignIn";
import Checkout from "./components/checkout/Checkout";
import Admin from "./components/Admin/Admin";
//import PayPalCheckout from "./components/checkout/PayPalCheckout/PayPalCheckout";
import Success from "./components/checkout/Success";
import ErrorPage from "./components/ErrorBoundary";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <ErrorPage />,
  },
  { path: "/registration", element: <SignUp /> },
  { path: "/signin", element: <SignIn /> },
  { path: "/checkout", element: <Checkout /> },
  { path: "/admin", element: <Admin /> },
  { path: "/success/:clientSecret", element: <Success /> },
]);

function App() {
  const initialOptions = {
    clientId:
      "Af497lHasWGPFr5U2b7wdginjE4-IGA-wN3aQFa2dWHW-UGYdkEdH9YueGFSpkIzEJWDZLthkiUV3rJ-",
  };
  return (
    <PayPalScriptProvider options={initialOptions}>
      <RentAmountContextProvider>
        <RouterProvider router={router} />
      </RentAmountContextProvider>
    </PayPalScriptProvider>
  );
}

export default App;
