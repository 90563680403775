import React, { useState, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";

import { auth } from "../../../firebase/firebase";
import { RentAmountContext } from "../../../context/AppContext";
import "./SignIn.css";

const SignIn = () => {
  const emailRef = useRef("");
  const passwordRef = useRef("");
  const [isForgotPassword, setIsForgetPassword] = useState(false);

  const navigate = useNavigate();

  const { errorMessage, setErrorMessage } = useContext(RentAmountContext);

  const handleSignIn = async (event) => {
    event.preventDefault();
    try {
      const emailPattern = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/;
      if (emailRef.current.value === "") {
        setErrorMessage("You entered an invalid email address");
        return;
      }

      if (!emailPattern.test(emailRef.current.value)) {
        setErrorMessage("You entered an invalid email address");
        return;
      }

      if (isForgotPassword) {
        await sendPasswordResetEmail(auth, emailRef.current.value);
        setErrorMessage("Password reset email was sent");
        navigate("/");
        return;
      }

      const userData = await signInWithEmailAndPassword(
        auth,
        emailRef.current.value,
        passwordRef.current.value
      );

      if (userData.user.email.includes("jousta")) {
        navigate("/admin");
      } else {
        navigate("/");
      }
      setErrorMessage("");
    } catch (error) {
      //Setting custom errors
      if (error?.message.includes("auth/user-not-found)")) {
        setErrorMessage("Your account does not exist.");
      } else if (error?.message.includes("auth/wrong-password")) {
        setErrorMessage("You entered a wrong password.");
      } else {
        setErrorMessage(error.message);
      }
    }
  };

  return (
    <form onSubmit={handleSignIn} className="sign-in">
      <p className="error-message">{errorMessage ? errorMessage : undefined}</p>
      <input type="email" placeholder="Email Address" ref={emailRef} required />

      {!isForgotPassword && (
        <input
          type="password"
          placeholder="Password"
          ref={passwordRef}
          required
        />
      )}

      {
        <input
          type="submit"
          value={`${!isForgotPassword ? "Sign In" : "Send"} `}
        />
      }
      {!isForgotPassword && (
        <p
          className="forgot-password"
          onClick={() => setIsForgetPassword(true)}
        >
          Forgot Password?
        </p>
      )}
    </form>
  );
};

export default SignIn;
