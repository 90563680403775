import React from "react";

import imagePath from "../assets/dog404.png";
import "./errorBoundary.css";

function ErrorBoundary() {
  return (
    <div className="page-error">
      <img src={imagePath} alt="" />
      <h2>A Dog Ate this Page</h2>
      <p>
        Your dog is cute but honestly a menace. Where are my shoes? Where is my
        graduation certificate? Where is the chocolate cake I baked for my
        Aunt’s birthday? And why did you take your dog to the vet on that same
        Thursday?!
      </p>
    </div>
  );
}

export default ErrorBoundary;
