import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { signOut, onAuthStateChanged } from "firebase/auth";

import { auth, firestore } from "../../firebase/firebase";

import "./Success.css";

function Success() {
  const [authUser, setAuthUser] = useState(null);

  //const { clientSecret } = useParams();
  //const microDepositVerificationUrl = `/successandverifydeposit/${clientSecret}`;

  useEffect(() => {
    const unsub = onAuthStateChanged(auth, (user) => {
      setAuthUser(user);
    });
    return () => unsub();
  }, []);

  useEffect(() => {
    const getAuthUser = async () => {
      try {
        if (authUser) {
          const docRef = doc(firestore, "tenants", authUser.uid);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            //console.log("Document data:", docSnap.data());
            const lastname = docSnap.data().lastName;
            const firstname = docSnap.data().firstName;
            const email = docSnap.data().email;

            await fetch("/sendMail", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                firstname,
                lastname,
                email,
              }),
            });
          } else {
            // docSnap.data() will be undefined in this case
            console.log("No such document!");
          }
        }
      } catch (err) {
        console.log(err.message);
      }
    };

    getAuthUser();
  }, [authUser]);

  return (
    <div className="success-wrapper">
      <div>
        <Link to="/" onClick={() => signOut(auth)}>
          Sign Out
        </Link>
      </div>
      <h2>Your rent payment was submitted successfully</h2>
      {/*
      <p>
        Please note that if you pay using your bank details (if you manually
        enter your routing and account numbers), you will see a $0.01 deposit in
        your account in 1-2 business days. Then you'll get an email with
        instructions to complete payment to Jousta LLC.
      </p>
      <div>
        <p>
          You can verify the deposits now if you see them in your bank
          statement.
        </p>
        <p className="verification-link-wrapper">
          <Link to={microDepositVerificationUrl}>
            I want to verify the deposits
          </Link>
        </p>
      </div>
      <p className="last-paragram">
        Again, if you did not manually enter your bank routing and account
        number to pay then you're all set.
      </p>
        */}
      <p>&copy; Joustallc {new Date().getFullYear()}</p>
    </div>
  );
}

export default Success;
