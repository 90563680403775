import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import CheckoutForm from "./CheckoutForm";

const stripePromise = loadStripe(
  "pk_live_51O3Xv2FE5VCdJza0AEsebEY3QOogdIHrhFmWp9v4I8d2yRfquCicuPeCjuHH4R7Nd1ztZxNb3j3UMH345QLKCrip00WSePgPtm" ||
    "pk_test_sAU0q7zJigw6fGlziKS3QcAk00l3o3vhKH"
);

function StripeElementProvider({ client_secret }) {
  /* const options = useMemo(() => {
    return {
      // passing the client secret obtained in step 3
      clientSecret: client_secret,
      // Fully customizable with appearance API.
      appearance: {
        theme: "stripe",

        variables: {
          colorText: "#ffffff",
          fontFamily: "sans-serif",
          spacingUnit: "2px",
          borderRadius: "4px",
        },
      },
    };
  }, [client_secret]);
*/

  const options = {
    clientSecret: client_secret,
  };
  return (
    <>
      <Elements stripe={stripePromise} options={options}>
        <CheckoutForm client_secret={client_secret} />
      </Elements>
    </>
  );
}

export default StripeElementProvider;
