import React from "react";
import "./CheckoutForm.css";

import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

function CheckoutForm({ client_secret }) {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `https://powerful-coast-25763-e3b1ac8d8c1e.herokuapp.com/success/${client_secret}`, //make sure you put an absolute url here. if not you'll get invalid url error
      }, //can also set ==>redirect: 'if_required', if you don't want to set return_urlequired',
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <div className="btn-submit-wrapper">
        <button className="btn-submit" disabled={!stripe}>
          Pay
        </button>
      </div>
    </form>
  );
}

export default CheckoutForm;
