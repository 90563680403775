import React, { useEffect, useState } from "react";

import SignUp from "../signInAnUp/SignUp";
import SignIn from "../signInAnUp/SignIn/SignIn";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth } from "../../firebase/firebase";

import "./Admin.css";

const Admin = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const unsub = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(user ? true : false);
    });
    return () => unsub();
  }, []);

  return isAuthenticated ? (
    <div className="admin-container">
      <nav>
        <div className="logo"></div>
        <ul>
          <li>Sign Up</li>
        </ul>
      </nav>

      <main>
        <SignUp />
      </main>
    </div>
  ) : (
    <SignIn />
  );
};

export default Admin;
