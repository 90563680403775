import React, { createContext, useEffect, useState } from "react";

export const RentAmountContext = createContext();

export const RentAmountContextProvider = ({ children }) => {
  const [rentAmount, setRentAmount] = useState(0);
  const [originalAmount, setOriginalAmount] = useState(0);
  const [isProcessingFee, setIsProcessingFee] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const onSetAmount = (amount) => {
    setRentAmount(amount);
  };

  const onSetOriginalAmount = (amount) => {
    setOriginalAmount(amount);
  };

  const onSetIsProcessingFee = (isProFee) => {
    setIsProcessingFee(isProFee);
  };

  const onSetErrorMessage = (error) => {
    setErrorMessage(error);
  };

  useEffect(() => {
    onSetAmount(rentAmount);
    onSetOriginalAmount(originalAmount);
    onSetIsProcessingFee(isProcessingFee);
    onSetErrorMessage(errorMessage);
  }, [rentAmount, originalAmount, errorMessage, isProcessingFee]);

  return (
    <RentAmountContext.Provider
      value={{
        rentAmount,
        originalAmount,
        isProcessingFee,
        setAmount: onSetAmount,
        setOriginalAmount: onSetOriginalAmount,
        setErrorMessage: onSetErrorMessage,
        setIsProcessingFee: onSetIsProcessingFee,
        errorMessage,
      }}
    >
      {children}
    </RentAmountContext.Provider>
  );
};
