import React, { useRef, useContext } from "react";
import { createUserWithEmailAndPassword } from "firebase/auth";

import { auth } from "../../firebase/firebase";

import { RentAmountContext } from "../../context/AppContext";

import "./SignUp.css";

function SignUp() {
  const firstnameRef = useRef(null);
  const lastnameRef = useRef(null);
  const emailSignupRef = useRef(null);

  const passwordSignupRef = useRef(null);

  const { errorMessage, setErrorMessage } = useContext(RentAmountContext);

  const handleSignUp = async (event) => {
    event.preventDefault();

    try {
      const emailPattern = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/;
      const namePattern = /^[a-zA-Z]+[a-zA-Z]+$/;

      if (emailSignupRef.current.value === "") {
        setErrorMessage("You entered an invalid email address");
        return;
      }
      if (firstnameRef.current.value === "") {
        setErrorMessage("First Name canot be empty and it must be a word");

        return;
      }

      if (lastnameRef.current.value === "") {
        setErrorMessage("Last Name cannot be empty and it must be a word");
        return;
      }

      if (!emailPattern.test(emailSignupRef.current.value)) {
        setErrorMessage("You entered an invalid email address");
        return;
      }

      if (!namePattern.test(firstnameRef.current.value)) {
        setErrorMessage("First Name must be a word");
        return;
      }

      if (!namePattern.test(lastnameRef.current.value)) {
        setErrorMessage("Last Name must be a word");
        return;
      }

      const response = await createUserWithEmailAndPassword(
        auth,
        emailSignupRef.current.value.toLowerCase(),
        passwordSignupRef.current.value
      );

      response.user.displayName =
        firstnameRef.current.value + " " + lastnameRef.current.value;

      setErrorMessage(
        "You have successfully signed " + response.user.displayName + " up"
      );
    } catch (error) {
      //Setting custom errors
      if (error?.message.includes("auth/user-not-found)")) {
        setErrorMessage("Your account does not exist.");
      } else if (error?.message.includes("auth/wrong-password")) {
        setErrorMessage("You entered a wrong password.");
      } else {
        setErrorMessage(error.message);
      }
    }
  };

  return (
    <div className="signup">
      <form onSubmit={handleSignUp} className="paymentform">
        <h2>Sign Up</h2>
        <p className="error-message">
          {errorMessage ? errorMessage : undefined}
        </p>
        <input
          type="text"
          placeholder="First Name"
          ref={firstnameRef}
          required
        />
        <input type="text" placeholder="Last Name" ref={lastnameRef} required />
        <input
          type="email"
          placeholder="Email Address"
          ref={emailSignupRef}
          required
        />
        <input
          type="password"
          placeholder="Password"
          ref={passwordSignupRef}
          required
        />

        <input type="submit" value="Sign Up" />
      </form>
    </div>
  );
}

export default SignUp;
