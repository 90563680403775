import React, { useRef, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaUnlockAlt } from "react-icons/fa";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth } from "../firebase/firebase";

//import SignUp from "../components/signInAnUp/SignUp";
import SignIn from "../components/signInAnUp/SignIn/SignIn";
import { RentAmountContext } from "../context/AppContext";

import "./Home.css";

function Home() {
  const amountRef = useRef();
  const achDebitRef = useRef();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  const {
    setAmount,
    setErrorMessage,
    errorMessage,
    setIsProcessingFee,
    setOriginalAmount,
  } = useContext(RentAmountContext);

  useEffect(() => {
    const unsub = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(user ? true : false);
    });
    return () => unsub();
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();

    const pattern = /\d/;

    if (!amountRef.current.value) {
      setErrorMessage("You must enter the rent amount");
      amountRef.current.style.border = "2px solid #892828";
      return;
    }

    if (!pattern.test(amountRef.current.value)) {
      setErrorMessage("Rent amount must be a number.");
      return;
    }

    if (!isFinite(amountRef.current.value)) {
      setErrorMessage("Rent amount must be a number.");
      return;
    }

    if (amountRef.current.value <= 0) {
      setErrorMessage("Rent amount cannot be zero or less");
      return;
    }

    let amount = amountRef.current.value;
    setOriginalAmount(amount);

    if (!achDebitRef.current.checked) {
      const isConfirmed = window.confirm(
        "Are you sure you want to pay with your credit or debit card. Click Ok if you agree to pay the 3% credit card processing fee or click cancel to change your method of payment."
      );

      if (isConfirmed) {
        setIsProcessingFee(true);
        amount = (parseFloat(amount) + parseFloat(amount * 0.03)).toFixed(2);
      } else {
        navigate("/");
        return;
      }
    }

    setAmount(amount);

    navigate("/checkout");
  };

  return (
    <div className="home">
      <p
        className="signout"
        style={{ textAlign: "right", fontSize: "2rem", fontWeight: "bold" }}
        onClick={() => signOut(auth)}
      >
        {isAuthenticated ? "Sign Out" : undefined}
      </p>
      <div className="home-box">
        <h1>Pay My Rent</h1>
        {!isAuthenticated ? (
          <div className="auth-items">
            <FaUnlockAlt />
            <SignIn />
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="amount-form">
            <p>{errorMessage ? errorMessage : undefined}</p>
            <h3>ACH Payment method is preferred and it is FREE.</h3>
            <h3>
              Payments made by credit or debit card are subject to a 3%
              processing fee.
            </h3>
            <div>
              <input type="checkbox" id="achdebit" ref={achDebitRef} />
              <label for="achdebit">I am paying with ACH Payment</label>
            </div>
            <input
              ref={amountRef}
              type="text"
              placeholder="Please enter rent amount"
            />
            <input type="submit" value="Continue" />
          </form>
        )}
      </div>
    </div>
  );
}

export default Home;
